import styled from 'styled-components';
import back from './img/fon-3.png'
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import mk_img_1 from './img/логотип-1.png'
import { Modalis } from '../pages/Modalis.jsx';
import { useState } from 'react';
import logo from './img/логотип-2.png';
import mk_img_11 from './img/111.jfif'
import mk_img_22 from './img/222.jfif'
import mk_img_33 from './img/333.jpg'
import mk_img_44 from './img/444.jpg'
import logo2 from './img/Veduschie.png';
import speak1 from './img/Ковычева.jpg'
import speak2 from './img/Третьякова.jpg'
import speak3 from './img/Данилов.jpg'
import speak4 from './img/Москвина.jpg'
import speak5 from './img/Ботников.jpg'
import speak6 from './img/Душенкова.jfif'
import speak7 from './img/Ермолина.jpg'
import speak8 from './img/Оглезнева.jpg'


const Back = styled.div`
    background-image: url(${back});
    height: 100%;
`

const TImg = styled.div`
    margin: 0 15%;
    background-image: url(${logo});
    background-size: contain;
    background-repeat: no-repeat;
    height: 400px;
    @media (width < 1440px){
        height: 300px;
    }
    @media (width < 1100px){
        height: 250px;
    }
    @media (width < 950px){
        height: 200px;
    }
    @media (width <= 720px){
        height: 180px;
    }
    @media (width <= 500px){
        height: 100px;
    }
`
const TImg1 = styled.div`
    margin: 0 15%;
    background-image: url(${logo2});
    background-size: contain;
    background-repeat: no-repeat;
    height: 300px;
    @media (width < 1440px){
        height: 300px;
    }
    @media (width < 1100px){
        height: 250px;
    }
    @media (width < 950px){
        height: 200px;
    }
    @media (width <= 720px){
        height: 180px;
    }
    @media (width <= 500px){
        height: 100px;
    }
`

const Mycard = styled(Card)`
    border: 3px solid #881D1B;
    border-radius: 10px;
`
const Myimg = styled(Card.Img)`
    max-height: 800px;
    object-fit: contain;
    object-position: 50% 40%;
`

const MyimgSlider = styled(Card.Img)`
    max-height: 800px;
    object-fit: cover;
    object-position: 50% 30%;
`
const Indents = styled.div`
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    margin: 0 15%;
    
    @media(width <= 700px){
        grid-template-columns: repeat(1,1fr);
    }
`
 export const MyButton = styled(Button)`
    background-color: #cd9d51;
    color: #881D1B;
    border: 2px solid #881D1B;
    font-size: 14pt;
    &:hover{
        background-color: #881D1B;
        color: #cd9d51;
        border: 2px solid #cd9d51;
    }
`
const MyBody = styled(Card.Body)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: #cd9d51;
`
const MyHeader = styled(Card.Header)`
    background-color: #881D1B;
    color: white;
`
const MyFooter = styled(Card.Footer)`
    background-color: #881D1B;
`
const Speaker = styled(Carousel)`
    margin: 0 15%;
    padding-bottom: 50px;
    .carousel-indicators button{
        background-color: #cd9d51;
        &.active{
            background-color: #881D1B;
        }
    }
    h3{
        font-size: 36pt;
        border-radius: 10px;
        color: #881D1B;
    @media (width <= 920px){
        font-size: 20pt;
    }
    @media (width <= 500px){
        hfont-size: 15pt;
    }
    }
`
const MyTaitl = styled(Card.Title)`
    &.h5{
        font-weight: 900;
    }
    
`
const TextHedear = styled.p`
    margin: 0 15%;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 25px;
    @media (width <= 720px){
        font-size: 20px;
    }
    @media (width <= 500px){
        font-size: 15px;
    }
`
const MyCaption = styled(Carousel.Caption) `
    position: static;
`




const mk_text = [{h:'Традиционный костюм народов Удмуртии', img:mk_img_11, title:'«Традиционный костюм народов Удмуртии: сохранение и актуализация»',text:'Ведущие мастер-класса: Третьякова Ирина Вениаминовна – директор МБУК «ЦКС Сарапульского района», председатель удмуртской организации центра социально-культурных инициатив «Высокий берег» Ковычева Елена Ивановна – доктор искусствоведения'},{h:'Хочу сказать', img:mk_img_22, title:'«Хочу сказать»',text:'Ведущие мастер-класса: Москвина Татьяна Никитична - художник - рестовратор, Ботников Владимир Федосеевич - краевед'},{h:'Амулет', img:mk_img_44, title:'',text:''},{h:'Магия народного пояса', img:mk_img_33, title:'Магия народного пояса. Практическое применение традиционных символов в изготовлении поясов',text:'Ведущие мастер-класса: Оглезнева Злата Львовна'}]
const spisok_speaker = [{name: 'Ковычева Елена Ивановна', img:speak1,},{name: 'Третьякова Ирина Вениаминовна', img:speak2,},{name: 'Данилов Петр Петрович', img:speak3,},{name: 'Москвина Татьяна Никитична', img:speak4,},{name: 'Ботников Владимир Федосеевич', img:speak5,},{name: 'Ермолина Наталья Петровна', img:speak7,},{name: 'Оглезнева Злата Львовна', img:speak8,},{name: 'Душенкова Татьяна Рудольфовна', img:speak6,}]



export const Main = () => {
    const [moadalActive, setModalActive] = useState(false)
    const [number, setNumber] = useState('')
    const [people, setPeople] = useState('')

    const Click = (e) => {
        setModalActive(true)
        setNumber(e.currentTarget.getAttribute('number'))
        setPeople(e.currentTarget.getAttribute('people'))
    }
    return (
        <>
        <Back>
            <TImg/>
            <TextHedear>Фестиваль «Учке мынэсьтым дэремме» («Оцените моё платье») на протяжении 10 лет популяризирует культуру народов Удмуртии, деятельность дизайнеров и театров моды, студий, мастеров народного творчества, погружает жителей и гостей Республики в особенности народного костюма. Фестиваль наглядно демонстрирует, что национальный костюм актуален и в современности.</TextHedear>
            <Indents>
            {mk_text.map((e) => {return (
                <Mycard className="text-center">
                    <MyHeader>{e.h}</MyHeader>
                        <MyBody>
                            <Myimg src={e.img} />
                            <MyButton variant="primary" number={e.h} people={e.text} onClick={Click}>Зарегистрироваться</MyButton>
                        </MyBody>
                    <MyFooter className="text-muted"></MyFooter>
                </Mycard>
            )})}
            <Modalis active={moadalActive} setActive={setModalActive} children={number} pl={people} />
            </Indents>
            <TImg1/>
            <Speaker fade='false'>
                {spisok_speaker.map((e) =>{return(
                    <Carousel.Item>
                        <MyimgSlider src={e.img} />
                        <MyCaption><h3>{e.name}</h3></MyCaption>
                    </Carousel.Item>                
                )})}
            </Speaker>
        </Back>
        
        </>
        
    )
}