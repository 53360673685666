import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { createPeople } from '../bindex';

import { MyButton } from '../component/Main';

const Backgroud = styled.div`
    display: flex;
    position: initial;
    background-color: rgba(0,0,0,0.5);
    height: 100%;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    &.modalis{
        opacity: 0;
        pointer-events: none;
    }
    &.active{
        opacity: 1;
        pointer-events: all;
        transition: 0.7s;
    }
`
const MyModalDialog = styled(Modal.Dialog)`
    @media (width <= 520px){
        min-width: 100px;
    }
    min-width: 500px;
    &.modalis{
        transform: scale(0);
        transition: 0.7s all;
    }
    
    &.active{
        transform: scale(1);
    }
`
const MyFormInput = styled(Form.Control)`
    &:invalid:required {
        background-image: linear-gradient(to right, #881D1B, #881D1B);
        border: 1px solid;
    }
`
const Myp = styled.p`
    font-size: 14px;
    color: white;
    background-color: #cd9d51; 
    margin-bottom: 0px;
    
`
const MyTitle = styled(Modal.Header)`
    color: white;
    background-color: #881D1B;
    border-bottom: 1px solid #881D1B;
`
const MyBody = styled(Modal.Body)`
    background-color: #cd9d51;
    p {
        color: white;
    }  
`
const MyInput = styled(InputGroup)`
    padding-bottom: 1rem;
    background-color: #cd9d51;  
`
const MyFooter = styled(Modal.Footer)`
    border-top: 1px solid #881D1B;
    background-color: #881D1B;
`


export const Modalis = ({active, setActive, children, pl}) => {
    const [name, setName] = useState('');
    const [surname, setSurName] = useState('');
    const [middlename, setMiddleName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const onSumbit = () => {
        if (name.length !== 0 && surname.length !== 0 && phone.length !== 0 && email.length !== 0){
            createPeople(name,surname,middlename, phone,email,children)
            setActive(false)
        }else if (name.length === 0) {
            alert('Вы не заполнили поле Имя')
        }else if (surname.length === 0) {
            alert('Вы не заполнили поле Фамилии')
        }else if (phone.length === 0) {
            alert('Вы не заполнили поле с Телефоном по которму с вами свяжуться')
        }else if (email.length === 0) {
            alert('Вы не заполнили поле Почту')
        }
        
    };
    return (
        <Backgroud className={ active ? 'modal show active' : 'modalis'} onClick={() => setActive(false)}>
      <MyModalDialog className={ active ? 'active' : 'modalis'} onClick={e => e.stopPropagation()}>
        <MyTitle closeButton onClick={() => setActive(false)}>
          <Modal.Title>Зарегистрироваться</Modal.Title>
        </MyTitle>
        <MyBody>
          <p>{children}</p>
          <p>{pl}</p>
        </MyBody>
            <MyInput>
                <InputGroup.Text id="inputGroup-sizing-default" >Имя</InputGroup.Text>
                <MyFormInput aria-label="Small" aria-describedby="inputGroup-sizing-sm" type='text' onChange={(e)=> setName(e.target.value)} required/>
            </MyInput>
            <MyInput >
                <InputGroup.Text id="inputGroup-sizing-default" >Фамилия</InputGroup.Text>
                <MyFormInput aria-label="Small" aria-describedby="inputGroup-sizing-sm" type='text' onChange={(e)=> setSurName(e.target.value)} required/>
            </MyInput>
            <MyInput >
                <InputGroup.Text id="inputGroup-sizing-default" >Отчество</InputGroup.Text>
                <MyFormInput aria-label="Small" aria-describedby="inputGroup-sizing-sm" type='text' onChange={(e)=> setMiddleName(e.target.value)} required/>
            </MyInput>
            <MyInput >
                <InputGroup.Text id="inputGroup-sizing-default" >Тел.</InputGroup.Text>
                <MyFormInput aria-label="Small" aria-describedby="inputGroup-sizing-sm" type='tel' onChange={(e)=> setPhone(e.target.value)} required/>
            </MyInput>
            <MyInput >
                <InputGroup.Text id="inputGroup-sizing-default" >Email</InputGroup.Text>
                <MyFormInput aria-label="Small" aria-describedby="inputGroup-sizing-sm" type='email' onChange={(e)=> setEmail(e.target.value)} required/>
            </MyInput>
            <Myp>Нажимая на кнопку вы соглашайтесь с Соглашением о обработки персональных данных</Myp>
        
        <MyFooter>
          <MyButton variant="primary" onClick={onSumbit}>Отправить заявку</MyButton>
        </MyFooter>
      </MyModalDialog>
    </Backgroud>
    )
}