import './App.css'
import 'bootstrap/dist/css/bootstrap.css';
import { Main } from './component/Main.jsx';



function App() {
  return (
    <>
      <Main/>
      
    </>
  );
}

export default App;
